export function debounce(fn, delay = 200,immediate) {
  let timer = null;
  let previous = null;
  return function () {
    let args = arguments;
    let now = +new Date();
    if(!previous) previous = now;
    if(now - previous > immediate){
      clearTimeout(timer);
      fn.apply(this,args);
      previous = now;
    }else{
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(this,args);
      }, delay);
    }
  };
}

export function chunk(array, count) {
  let result = [];
  //遍历输出成员
  array.forEach((item, index) => {
    let temp = Math.floor(index / count);
    //检验数组是否初始化
    if (!(result[temp] instanceof Array)) {
      result[temp] = new Array();
    }
    result[temp].push(item);
  });
  return result;
}

export function isEmptyObj(obj) {
  return obj && Object.keys(obj).length == 0;
}
