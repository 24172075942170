<template>
  <div class="blog-item">
    <div class="blog-item-pic">
      <img :src="item.imgUrl" alt="" />
    </div>
    <div class="blog-item-detail">
      <div class="title">{{ item.bloggerName }}</div>
      <div class="authentication-info">
        {{ item.authenticationInfo }}
      </div>
      <div class="blogger-info">
        声望值&nbsp;{{ item.prestigeValue }} &nbsp;|&nbsp;执业等级&nbsp;{{
          item.practiceLevel
        }}&nbsp;级
      </div>
      <div class="products-num">
        知识产品·<span class="blue">{{ item.productsNum }}项</span>
      </div>
      <div class="group">
        <span class="group-follow">
          <img
            v-if="item.followed"
            :src="require('assets/images/followed.png')"
            alt=""
          />
          <img v-else :src="require('assets/images/follow.png')" alt="" />
          <span v-if="item.followed" class="txt followed-txt">已关注</span>
          <span v-else class="txt">关注&nbsp;{{ item.follows }}万</span>
        </span>
        <span class="group-like">
          <img
            v-if="!item.liked"
            :src="require('assets/images/fabulous.png')"
            alt=""
          />
          <img v-else :src="require('assets/images/fabulous-red.png')" alt="" />
          <span v-if="!item.liked" class="txt"
            >点赞&nbsp;{{ item.likes }}万</span
          >
          <span v-else class="txt red">已赞</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CopyrightBlogItem",
  props: {
    itemInfo: {
      type: Object,
    },
  },
  data() {
    return {
      item: this.itemInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
.blog-item {
  @include flex-center(column);
  width: 210px;
  background-color: #fff;
  box-shadow: 0px 2px 12px 0px rgba(162, 170, 184, 0.22);
  cursor: pointer;
  &-pic {
    @include flex-start;
    width: 210px;
    height: 210px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-detail {
    width: 100%;
    padding: 0 13px 20px;
    box-sizing: border-box;
    .title {
      margin-top: 19px;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .authentication-info {
      margin-top: 18px;
      font-size: 14px;
      font-weight: bold;
      color: #4587ff;
    }
    .blogger-info {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
    }
    .products-num {
      margin-top: 8px;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      .blue {
        color: #4587ff;
      }
    }
    .group {
      @include flex-between;
      margin-top: 14px;
      &-follow {
        @include flex-start;
        img {
          width: 15px;
          height: 15px;
        }
        .txt {
          margin-left: 7px;
          font-size: 14px;
          font-weight: 500;
          color: #4587ff;
        }
        .txt.followed-txt {
          color: #999999;
        }
      }
      &-like {
        @include flex-start;
        height: 19px;
        img {
          width: 15px;
          height: 15px;
        }
        .txt {
          margin-left: 7px;
          font-size: 14px;
          font-weight: 500;
          color: #4587ff;
        }
        .txt.red {
          color: #eb441e;
        }
      }
    }
  }
}
</style>
