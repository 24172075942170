<template>
  <div class="copyright-banner" v-show="show">
    <div class="home-header">
      <div class="home-head">
        <h3>热烈庆祝深圳写手智能科技公司</h3>
        <h3>双子星软件“成语”“反抄袭”</h3>
        <div class="home-segistr">2022年5月20日正式上线运行</div>
        <div class="segistr-now" @click="segistrNow">现在注册</div>
        <div class="segistr-discounts">现在注册获赠5000字符</div>
      </div>
    </div>
    <!-- <el-carousel trigger="click" arrow="never" height="280px">
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <img
          @click="imgClick(index)"
          class="img"
          width="100%"
          height="280px"
          :src="item.imgUrl"
          alt=""
        />
      </el-carousel-item>
    </el-carousel> -->

    <registrationDialog
      @registerClick="registerClick"
      ref="registrationDialog"
    />
    <Header ref="headerRegister" />
  </div>
</template>

<script>
import registrationDialog from "components/Exclusive-registration/registrationDialog.vue";
import Header from "components/header/Header.vue";
export default {
  components: {
    registrationDialog,
    Header,
  },
  name: "CopyrightBanner",
  props: {
    showBanner: {
      type: Boolean,
      default: true,
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: this.showBanner,
      bannerList: this.images,
    };
  },

  methods: {
    registerClick(val) {
      this.$refs["headerRegister"].register_flag = val;
    },
    imgClick(i) {
      // if (i == 0) {
      //   this.$refs["registrationDialog"].isDialogShow = true;
      // }
    },
    segistrNow() {
      this.$refs["headerRegister"].register_flag = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.home-header {
  width: 100%;
  height: 281px;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
  }
  .home-head {
    width: 100%;
    height: 100%;
    background: url("../../assets/images/01/01.png") no-repeat;
    background-size: 100% 100%;
    padding: 37px 0 33px 201px;
    box-sizing: border-box;
    text-align: left;
    h3 {
      font-size: 27px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 13px;
    }
    .home-segistr {
      font-size: 21px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 29px;
      margin-top: 6px;
    }
    .segistr-now {
      cursor: pointer;
      width: 142px;
      height: 38.6px;
      background: url("../../assets/images/01/02.png") no-repeat;
      background-size: 100% 100%;
      margin: 36px 0 13px;
      text-align: center;
      line-height: 38.6px;
      font-size: 17px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      text-shadow: 0px 0px 28px #1067e8;
    }
    .segistr-discounts {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #a4fffd;
      // line-height: 29px;
    }
  }
}
::v-deep .el-carousel__indicators {
  left: 50%;
  transform: translateX(-50%);
  .el-carousel__button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}

.img {
  cursor: pointer;
}
::v-deep .wm-antiplagiarism-header {
  width: 0;
  height: 0;
  overflow: hidden;
  min-width: auto;
}
</style>
