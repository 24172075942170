<template>
  <div class="products-item" :class="productInfo.length < 4 ? 'float' : ''">
    <div class="products-item-pic">
      <img
        :src="$store.state.onlineBasePath + item.productIntroduction"
        alt=""
      />
    </div>
    <div class="products-item-detail">
      <div class="title">{{ item.productName }}</div>
      <div class="price">
        <span class="y">￥</span>
        {{ item.productPrices }}
        <!-- <span class="member">会员 : 0.00</span> -->
      </div>
      <div class="business">{{ item.business }}</div>
      <!-- <div class="group"> -->
      <!-- <span class="group-evaluate"
          >{{ item.evaluate }}<span>条评论</span></span
        > -->
      <!-- <span class="group-like">
          <img
            :src="
              !item.collectionStatus
                ? require('assets/images/like.png')
                : require('assets/images/like-red.png')
            "
            alt=""
            @click.stop.prevent="isLike"
          />
        </span>
        <span class="group-shopcar">
          <img :src="require('assets/images/car.png')" alt="" />
          立即购物
        </span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { addOrDel } from "api/copyrightService";
export default {
  name: "CopyrightProductItem",
  props: {
    productInfo: {
      type: Object,
    },
  },
  data() {
    return {
      like: false,
      item: this.productInfo,
    };
  },
  mounted() {
    console.log(this.item);
  },
  methods: {
    //收藏商品
    async isLike() {
      let data = {
        productId: this.productInfo.id,
      };
      let res = await addOrDel(data);
      if (res.code == 200) {
        this.productInfo.collectionStatus = !this.productInfo.collectionStatus;
        if (this.productInfo.collectionStatus) {
          this.$message({
            type: "success",
            message: "收藏成功",
          });
        } else {
          this.$message({
            type: "success",
            message: "取消收藏",
          });
        }
      }
    },
  },
  created() {
    this.productInfo;
  },
};
</script>

<style lang="scss" scoped>
.float {
  float: left;
  margin-right: 20px;
}
.products-item {
  @include flex-center(column);
  width: 210px;
  background-color: #fff;
  box-shadow: 0px 2px 12px 0px rgba(162, 170, 184, 0.22);
  cursor: pointer;
  &-pic {
    @include flex-start;
    width: 210px;
    height: 210px;
    img {
      image-rendering: -webkit-optimize-contrast;
      width: 100%;
      height: 100%;
    }
  }
  &-detail {
    width: 100%;
    padding: 0 13px 11px;
    box-sizing: border-box;
    .title {
      margin-top: 19px;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .price {
      .y {
        margin-right: 5px;
      }
      .member {
        float: right;
        display: inline-block;

        padding: 2px;
        border: 1px solid #eb441e;
        font-size: 12px;
      }
      margin-top: 18px;
      font-size: 16px;
      font-weight: bold;
      color: #eb441e;
    }
    .business {
      margin-top: 12px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
    .group {
      @include flex-between;
      margin-top: 9px;
      &-evaluate {
        font-size: 12px;
        font-weight: 400;
        color: #4587ff;
        > span {
          color: #666;
        }
      }
      &-like {
        height: 12px;
        img {
          width: 12px;
          height: 12px;
        }
      }
      &-shopcar {
        @include flex-start;
        img {
          width: 14px;
          height: 13px;
          vertical-align: middle;
          margin-right: 3px;
        }
        font-size: 12px;
        font-weight: 400;
        color: #eb441e;
      }
    }
  }
}
</style>
