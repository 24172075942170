<template>
  <div class="slideBtn">
    <span class="left">
      <img :src="require('assets/images/slide_left.png')" alt="" />
    </span>
    <span class="right">
      <img :src="require('assets/images/slide_right.png')" alt="" />
    </span>
  </div>
</template>

<script>
export default {
  name: "CopyrightSlideBtn",
};
</script>

<style lang="scss" scoped>
.slideBtn {
  @include flex-between;
  @include noselect;
  width: 31px;
  span {
    cursor: pointer;
  }
}
</style>
