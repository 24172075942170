import { render, staticRenderFns } from "./CopyrightSortBox.vue?vue&type=template&id=07301bba&scoped=true&"
import script from "./CopyrightSortBox.vue?vue&type=script&lang=js&"
export * from "./CopyrightSortBox.vue?vue&type=script&lang=js&"
import style0 from "./CopyrightSortBox.vue?vue&type=style&index=0&id=07301bba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07301bba",
  null
  
)

export default component.exports